
import ViewModel from '@/models/ViewModel'
import { uniqueId } from 'lodash'
import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DynamicRatePicker from '@/components/DynamicRatePicker/DynamicRatePicker.vue'
import {
  billing_source, billing_mode, billing_calendar, billing_mode_open_ended,
} from '../options'

@Component({
  components: {
    IconAction,
    SelectPicker,
    DynamicRatePicker,
  },
})
export default class SubitemTable extends ViewModel {
  // Bootstrap table reference
  @Ref()
  public readonly dataTable!: any

  public uuid: string = uniqueId()

  @Prop({ default: '' })
  public scoped_view!: string

  @Prop({ default: () => [] })
  public base_items!: any

  @Prop({ default: () => [] })
  public fields!: any

  @Prop({ default: () => [] })
  public readonly checked!: Array<string>

  @Prop()
  public active_navigation_id!: string

  public local_active_navigation_id: string = ''

  private local_dynamic_cost_rate_id: null | string = null

  public get table_options() {
    return {
      billing_source,
      billing_mode,
      billing_calendar,
      billing_mode_open_ended,
    }
  }

  public get items() {
    return this.base_items
  }

  public set items(val: any) {
    this.$emit('update:base_items', val)
  }

  // Returns checked prop to prevent updating prop
  public get local_checked(): Array<string> {
    return this.checked
  }

  // Update parent checked prop
  public set local_checked(value: Array<string>) {
    this.$emit('update:checked', value)
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  get item_list() {
    return this.items
  }

  @Watch('active_navigation_id')
  public onActiveNavigationIdChange(id: string) {
    this.local_active_navigation_id = this.active_navigation_id
  }

  public change_status(row: any, value: string) {
    row.item.status = value
  }

  private rowClass(row: any) {
    if (row && row.id === this.local_active_navigation_id) {
      return 'active-row'
    }

    return ''
  }

  public mounted() {
    this.local_active_navigation_id = this.active_navigation_id
  }
}
