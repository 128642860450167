/** @format */

import Reconciliation from '@/models/Reconciliation'
import WebMessage from '@/models/WebMessage'
import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'

@Module({
  dynamic: true, store, namespaced: true, name: 'reconciliation_extras',
})
export default class ReconciliationModule extends VuexModule {
  public parent_details: any = {}

  public last_scope: string = 'client'

  public unedited: string = ''

  public display_smart_navigation: boolean = false

  public unsaved_items: boolean = false

  @Mutation
  public updateLastScope(payload: any) {
    this.last_scope = payload
  }

  @Mutation
  public parentDetails(payload: any) {
    this.parent_details = payload
  }

  @Mutation
  public toogleSmartNav(payload: any) {
    this.display_smart_navigation = payload
  }

  @Mutation
  public toogleUnsaved(payload: any) {
    this.unsaved_items = payload
  }

  @Mutation
  public toggleUnedited(payload: any) {
    this.unedited = payload
  }

  @Action({ commit: 'parentDetails' })
  public toggleRowInfo(payload: Reconciliation) {
    return payload
  }

  @Action({ commit: 'toogleSmartNav' })
  public toggle_smart_nav(payload: any) {
    return payload
  }

  @Action({ commit: 'toogleUnsaved' })
  public toggleNotSaved(payload: any) {
    return payload
  }

  @Action({ commit: 'toggleUnedited' })
  public setUnedited(payload: Array<any>) {
    return JSON.stringify(payload)
  }

  @Action
  public warning() {
    WebMessage.warning('You have unsaved changes.', null)
  }

  @Action({ commit: 'updateLastScope' })
  public setLastScope(payload: string) {
    return payload
  }
}
