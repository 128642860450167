
import {
  Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ReconciliationGroup from '@/models/ReconciliationGroup'
import Widget from '@/components/Widget/Widget.vue'
import { uniqueId, snakeCase, clone } from 'lodash'
import WebMessage from '@/models/WebMessage'
import DataTable from '@/components/DataTable/index.vue'
import ReconciliationModule from '@/store/model/ReconciliationModule'
import Reconciliation from '@/models/Reconciliation'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import { PaginationOptionsAll, parseHtmlTable } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DropArea from '@/components/DropArea/DropArea.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import DynamicRatePicker from '@/components/DynamicRatePicker/DynamicRatePicker.vue'
import field_list from '../fields'
import details_fields from '../details_fields'
import SubitemTable from './SubItemTable.vue'
import publisher_fields from '../publisher_fields'
import publisher_details_fields from '../publisher_details_fields'
import NavigationFooter from './NavigationFooter.vue'
import { billing_source_parent, billing_mode_parent } from '../options'
import BatchEditReconciliation from './BatchEditReconciliation.vue'

@Component({
  components: {
    IconAction,
    SubitemTable,
    Widget,
    SelectPicker,
    DataTable,
    NavigationFooter,
    DropArea,
    CheckboxInput,
    DynamicRatePicker,
    BatchEditReconciliation,
  },
})
export default class ReconciliationMediaPlanTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public reconciliationModule = getModule(ReconciliationModule)

  public uuid: string = uniqueId()

  // Flattens checked control array, returning all selected IDs in a single array
  public get checked(): string[] {
    // return this.checked_control.reduce((acc: string[], cur: string[]) => acc.concat(cur), [])
    let acc: Array<any> = []
    Object.entries(this.checked_control).forEach((c: any) => {
      acc.push(c[0])
      c[1]?.forEach(x => acc.push(x))
    })
    return acc
  }

  private local_dynamic_cost_rate_id: null | string = null

  public show_batch: boolean = false

  public mainCheckbox: boolean = false

  @Prop({ default: '' })
  public scoped_view!: string

  @Watch('scoped_view')
  public onChange(val: any) {
    this.page = 1
    this.resetFilters()
  }

  @Prop({ default: [] })
  public query!: any

  public active_navigation_id: string = ''

  public active_navigation_group_id: string = ''

  public loading: boolean = false

  public modal = {
    download: false,
  }

  public page: number = 1

  public page_size: number = 25

  public selected_row: Object = {}

  public selected_option: String = ''

  public fieldFilters: any = {}

  public records: number = 0

  public checked_group: string[] = []

  public checked_control: any = []

  // List of fields, populated on component mount
  public fields: Array<any> = []

  // List of fields, populated on component mount
  public details_field_list: Array<any> = []

  public table_data: any = []

  // Selected rows
  public selected: string[] = []

  public sortBy: string = 'period'

  public sortDesc: boolean = false

  public showAllDetails: boolean = false

  public allSelected: boolean = true

  public selected_media_plan: object = {}

  public group_list: Array<any> = []

  // Default table pagination options
  public get pagination_options() {
    return PaginationOptionsAll
  }

  public get table_options() {
    return {
      billing_source: billing_source_parent,
      billing_mode: billing_mode_parent,
    }
  }

  private save_loading: boolean = false

  private export_loading: boolean = false

  private apply_table_filters: boolean = false

  private updateDateTimeout: any = null

  @Watch('allSelected')
  public onIsAllSelectedChange(val: any) {
    if (val) this.loadCheckbox()
    else this.resetSelection()
  }

  @Watch('checked_group')
  private onCheckedGroupChange(val: any) {
    if (!val || val.length === 0) this.allSelected = false
    if (val.length === this.group_list.length) this.allSelected = true
  }

  @Watch('query')
  public onQueryChange(val: any) {
    if (val) this.updateData()
  }

  @Watch('page')
  public onPageChange(val: any) {
    if (val) this.updateData()
  }

  @Watch('page_size')
  private onPageSizeChange() {
    this.page = 1
    this.updateData()
  }

  /**
   * Check if any items need to be saved
   */
  public get hasUnsavedUpdated() {
    return this.group_list.some((group: ReconciliationGroup) => group.is_dirty)
  }

  public get selected_options() {
    return [...this.checked_group, ...this.checked_control]
  }

  public addDynamicRateToRow(index: any) {
    this.group_list[index].dynamic_cost_rate_id = this.local_dynamic_cost_rate_id

    this.$root.$emit('bv::hide::popover')
    this.local_dynamic_cost_rate_id = null
    this.dataTable.refresh()
  }

  /**
   * Triggered on batch update confirm, update items based on the batch parameters
   */
  public onBatchConfirm(payload: any) {
    if (payload.mode === 'filter') {
      // Build data paylod
      let data: any = {}
      payload.selected.forEach((prop: string) => {
        // @ts-ignore
        data[prop] = payload.values[prop]
      })

      // Send batch edit to server
      this.checkDirtyRecords(() => {
        WebMessage.success(
          'Updating records, please wait... Please note that changes made to approved items will be ignored!',
        )
        ReconciliationMonthly.batch(
          this.$route.params.period,
          this.scoped_view,
          this.query,
          data,
        ).then(() => {
          this.refresh()
          this.$emit('saved')
        })
      }, 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it before running the batch update?')
      return
    }

    // Apply changes to items
    this.group_list.forEach(group => {
      group.items.forEach((item: Reconciliation) => {
        // Only apply updates if item status is pending and if it is selected or if mode is 'visible'
        if (
          item.status === 'pending'
          && (payload.mode === 'visible' || this.checked.includes(item.id))
        ) {
          payload.selected.forEach((prop: string) => {
            // @ts-ignore
            item[prop] = payload.values[prop]
          })
        }
      })
    })
  }

  public toggleRowInfo(cond: any) {
    this.layout.toggleRowInfo(cond)
  }

  /**
   * Hide All sub items
   */
  private showAll() {
    this.showAllDetails = true
    this.toggleDetails('all', 'show')
  }

  /**
   * Show all sub items
   */
  private hideAll() {
    this.showAllDetails = false
    this.toggleDetails('all', 'hide')
  }

  /**
   * Toggle sub items
   */
  private toggleDetails(target: string, action: string = 'toggle') {
    if (target === 'all') {
      this.group_list.forEach(group => {
        group._showItems = action === 'show' ? true : action === 'hide' ? false : !group._showItems
      })

      return
    }
    let record = this.group_list.find((item: any) => item.uuid === target)
    if (record) {
      record._showItems = action === 'show' ? true : action === 'hide' ? false : !record._showItems
    }
  }

  // Clear all selection
  public resetSelection() {
    Vue.set(this, 'checked_group', [])
    Vue.set(this, 'checked_control', [])
  }

  /**
   * Triggered on group check box change, it updates the sub items check state
   */
  private toogleItems(group: ReconciliationGroup) {
    // Check if group is checked
    const isChecked = this.checked_group.includes(group.uuid)
    // Vue Set is Required to ensure that the UI is updated properly on array change
    Vue.set(
      this.checked_control,
      group.uuid,
      isChecked ? group.items.map((item: Reconciliation) => item.id) : [],
    )
  }

  // Check if it has at least one group checked but not all has checked records but not all
  public get indeterminate(): boolean {
    return this.checked_group.length > 0 && this.checked_group.length < this.group_list.length
  }

  /**
   * Check if a group has at least one checked item but not all
   */
  public isGroupIndeterminate(group: ReconciliationGroup) {
    return (
      this.checked_control[group.uuid]
      && this.checked_control[group.uuid].length > 0
      && this.checked_control[group.uuid].length < group.items.length
    )
  }

  /**
   * Map raw pasted data into desired object
   */
  private mapPastedData(data: any) {
    if (!data || data.length === 0) {
      return
    }

    // Allowed Fields
    const allowed_fields: any = {
      publisher: {
        id: 'string',
        billing_source: 'snake_case',
        '3rd_party_impressions': 'integer',
        reconciliated_impressions: 'integer',
        cost_rate: 'float',
      },
      client: {
        id: 'string',
        item_code: 'string',
        billing_source: 'snake_case',
        billing_mode: 'snake_case',
        '3rd_party_impressions': 'integer',
        reconciliated_impressions: 'integer',
      },
    }

    // Check for required Fields
    if (
      (!data[0].item_code && !data[0].id)
      || (!data[0]['3rd_party_impressions']
        && !data[0].billing_source
        && !data[0].billing_mode
        && !data[0].billing_calendar
        && !data[0].cost_rate)
    ) {
      WebMessage.error(
        'Pasted data is not in the correct format, pelase make sure to include the "ID" or "Item Code" and one or more of the allowed headers.',
      )
      return
    }

    // Clean up pasted data and format data types
    const clean_data: any = []
    data.forEach((row: any) => {
      const mapped_row: any = {}
      Object.keys(row).forEach((key: any) => {
        let map_key = key
        if (key === '3rd_party_impressions') {
          map_key = 'reconciliated_impressions'
        }
        if (allowed_fields[this.scoped_view][key]) {
          if (allowed_fields[this.scoped_view][key] === 'snake_case') {
            mapped_row[map_key] = snakeCase(row[key])
          } else if (allowed_fields[this.scoped_view][key] === 'integer') {
            mapped_row[map_key] = parseInt(row[key].replace(/[^\d.-]/g, ''))
          } else if (allowed_fields[this.scoped_view][key] === 'float') {
            mapped_row[map_key] = parseFloat(row[key].replace(/[^\d.-]/g, ''))
          } else {
            mapped_row[map_key] = row[key]
          }
        }
      })
      clean_data.push(mapped_row)
    })

    return clean_data
  }

  /**
   * Runs whenever an user paste date into the page, this function will parse the data and display it in the modal
   */
  private onPaste(e: any) {
    const content = e.clipboardData?.getData('text/html')
    if (content) {
      // Parse html table
      const data = parseHtmlTable(content)
      // Map data to object
      const clean_data = this.mapPastedData(data)

      // Import Data
      if (!clean_data || clean_data.length === 0) {
        return
      }
      this.checkDirtyRecords(() => {
        WebMessage.success(
          'Importing pasted data, please wait... Please note that changes made to approved items will be ignored!',
        )
        ReconciliationMonthly.import(this.$route.params.period, null, clean_data).then(() => {
          this.refresh()
          this.$emit('saved')
        })
      }, 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it before importing the pasted data?')
    }
  }

  private fileDrop(files: FileList) {
    this.checkDirtyRecords(() => {
      WebMessage.success(
        'Processing the file, please wait... Please note that changes made to approved items will be ignored!',
      )
      ReconciliationMonthly.import(this.$route.params.period, files).then(() => {
        this.refresh()
        this.$emit('saved')
      })
    }, 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it before importing the file?')
  }

  private onKeyDown(e: KeyboardEvent) {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') {
      e.preventDefault()
      this.save()
    }
  }

  /**
   * Capture the paste event and get the data
   */
  private registerEvent() {
    document.onpaste = this.onPaste
    document.addEventListener('keydown', this.onKeyDown)
  }

  /**
   * Clear paste event to prevent triggering the event outside the page
   */
  private unregisterEvent() {
    document.onpaste = null
    document.removeEventListener('keydown', this.onKeyDown)
  }

  public mounted() {
    this.registerEvent()
    this.fields = field_list
    this.details_field_list = details_fields
    this.loadFilters()
    this.updateData()
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
  }

  public toggleSubItem(row: any) {
    row.toggleDetails(row)
    this.selected_row = row.item
  }

  public confirmSelectedRows(approve = true) {
    const total = this.checked.length
    let unapproved = {
      msg: `This action will unapprove all <strong>${total}</strong> selected items, are you sure that you want to proceed?`,
      title: 'Unapprove Selected Items',
    }
    let modal_settings = {
      msg: `This action will approve all <strong>${total}</strong> selected items, are you sure that you want to proceed?`,
      title: 'Approve Selected Items',
    }
    if (!approve) {
      modal_settings = unapproved
    }
    WebMessage.confirm(modal_settings.msg, modal_settings.title, {
      okTitle: approve ? 'Approve' : 'Unapprove',
      cancelTitle: 'Cancel',
    }).then((confirm: boolean) => {
      if (confirm) {
        // Approve checked Items
        this.group_list.forEach(group => {
          group.items.forEach((item: Reconciliation) => {
            if (this.checked.includes(item.id)) item.status = approve ? 'approved' : 'pending'
          })
        })
      }
    })
  }

  public get show_fields() {
    if (this.scoped_view !== 'client') {
      this.fields = publisher_fields
      this.details_field_list = publisher_details_fields
    } else {
      this.fields = field_list
      this.details_field_list = details_fields
    }
    return this.fields.filter((f: any) => f.show)
  }

  public get show_detail_fields() {
    return this.details_field_list.filter((f: any) => f.show)
  }

  public refresh() {
    this.updateData()
  }

  public change_status(row: any, value: string) {
    row.item.status = value
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.$emit('update:query', [])
    this.clearFilters()
    this.refresh()
  }

  public importData() {
    // @ts-ignore
    this.$refs.fileHandler.$refs.file_input.click()
  }

  public exportData() {
    return this.checkDirtyRecords(() => {
      if (this.query.length === 0) {
        this.apply_table_filters = false
        return this.confirmExport()
      }
      this.apply_table_filters = true
      this.modal.download = true
    })
  }

  public confirmExport() {
    this.export_loading = true
    ReconciliationMonthly.export(
      [this.$route.params.period],
      this.apply_table_filters ? this.query : null,
    )
      .then(() => {
        this.export_loading = false
      })
      .catch(() => {
        this.export_loading = false
      })
  }

  /**
   * Check if has unsaved records
   * Invoke on table data change(pagination, search, import), leave page
   */
  public checkDirtyRecords(
    callback: any,
    message: string = 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it first?',
  ) {
    // check if dirty
    if (this.hasUnsavedUpdated) {
      return WebMessage.confirm(message, 'Unsaved Items', {
        okTitle: 'Save',
        cancelTitle: 'Discard',
      }).then((save: boolean) => {
        if (save) {
          this.save().then(() => (callback ? callback() : Promise.resolve()))
        } else {
          return callback ? callback() : Promise.resolve()
        }
      })
    }
    return callback ? callback() : Promise.resolve()
  }

  public confirmScope(update: boolean = false): string {
    if (!update) {
      if (this.scoped_view !== this.reconciliationModule.last_scope) {
        return this.reconciliationModule.last_scope
      }
      return this.scoped_view
    }
    this.reconciliationModule.setLastScope(this.scoped_view)
    return this.scoped_view
  }

  /**
   * Save all dirty records
   */
  public save() {
    this.save_loading = true
    return Reconciliation.save(
      this.confirmScope(),
      this.group_list.reduce(
        (carry: Reconciliation[], group: ReconciliationGroup) => [...carry, ...group.items],
        [] as Reconciliation[],
      ),
    ).then(() => {
      this.save_loading = false
      this.$emit('saved')
      this.confirmScope(true)
    })
  }

  public updateData() {
    // Prevent update when loading
    if (this.loading) return
    return this.checkDirtyRecords(() => {
      this.loading = true
      const field_filters = Object.keys(this.fieldFilters)
        .filter((key: string) => this.fieldFilters[key] !== '')
        .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
      this.syncFilters()

      // Multiple actions can trigger a refresh, due to that we use timeout to prevent multiple requests an use the most recent filters
      if (this.updateDateTimeout) clearTimeout(this.updateDateTimeout)
      this.allSelected = false
      this.updateDateTimeout = setTimeout(() => {
        ReconciliationGroup.paginate(
          {
            page_size: this.page_size,
            page: this.page,
            order_by: this.sortBy,
            order: this.sortDesc ? 'desc' : 'asc',
            query: [...this.query, ...field_filters],
          },
          this.scoped_view,
          this.$route.params.period,
        ).then(result => {
          this.records = result.records
          this.group_list = result.data
          this.confirmScope(true)
          this.loading = false
          this.loadCheckbox()
          this.allSelected = true
        })
      }, 150)
    })
  }

  public loadCheckbox() {
    this.resetSelection()
    // this.checked_group = this.group_list.map((d) => d.uuid)
    // Vue.set(
    //   this,
    //   'checked_group',
    //   this.group_list.map((d) => d.uuid),
    // )

    this.group_list.forEach((r: any) => {
      this.checked_group.push(r.uuid)
      Vue.set(
        this.checked_control,
        r.uuid,
        r.items.map(i => i.uuid),
      )
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: `reconciliation_${this.scoped_view}`,
      data: null,
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: `reconciliation_${this.scoped_view}`,
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter(`reconciliation_${this.scoped_view}`).then((filter: any) => {
      if (filter) {
        this.$emit('update:query', filter.query)
        this.fieldFilters = filter.fieldFilters
      }
    })
  }
}
