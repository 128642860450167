
import { Component, Watch, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataForm from '@/components/DataForm/DataForm.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import DataTable from '@/components/DataTable/index.vue'
import Widget from '@/components/Widget/Widget.vue'
import { getModule } from 'vuex-module-decorators'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import ReconciliationModule from '@/store/model/ReconciliationModule'
import moment from 'moment'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { query_settings, publisher_query_settings } from './options'
import HeaderInfo from './components/HeaderInfo.vue'
import SubitemTable from './components/SubItemTable.vue'
import NavigationFooter from './components/NavigationFooter.vue'
import ReconciliationMediaPlanTable from './components/ReconciliationMediaPlanTable.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    DataForm,
    PageHeader,
    Widget,
    DataTable,
    HeaderInfo,
    IconAction,
    SubitemTable,
    ReconciliationMediaPlanTable,
    NavigationFooter,
    SearchInput,
  },
})
export default class ReconciliationView extends ViewModel {
  public reconciliationModule = getModule(ReconciliationModule)

  @Ref() readonly mediaplanTable!: HTMLFormElement

  @Ref() readonly searchInput!: HTMLFormElement

  private overview: any = {
    advertiser_rate: null,
    publisher_rate: null,
    discrepancy: null,
    discrepancy_impressions: null,
    discrepancy_reconciliated_impressions: null,
    revenue_total: null,
    cost_total: null,
  }

  public scope_view: string = 'client' // publisher, client

  public show_filter_helper: boolean = false

  public get period(): string {
    return this.$route.params.period
  }

  public get selected_parent() {
    let selected = this.reconciliationModule.parent_details
    this.selected_row = selected
    return selected
  }

  public get hasUnsavedUpdated() {
    return this.reconciliationModule.unsaved_items
  }

  @Watch('scope_view')
  public onChange(val: any, old_val: any) {
    this.reconciliationModule.setLastScope(old_val)
  }

  public query: string[] = []

  private scope_view_options = [
    { text: 'Media Plan', value: 'client' },
    { text: 'Publisher', value: 'publisher' },
  ]

  public ready = false

  public selected_option: String = ''

  public selected_row: any = null

  public pasted_data: any = null

  public table_data_list: any = []

  public get query_settings() {
    if (this.scope_view === 'client') {
      return query_settings
    }
    return publisher_query_settings
  }

  public get is_partial() {
    return moment(`${this.period}01`, 'YYYYMMDD')
      .endOf('month')
      .add(48, 'hours')
      .isAfter(moment())
  }

  public beforeRouteLeave(to: object, from: object, next: any) {
    if (!this.mediaplanTable.hasUnsavedUpdated) {
      next()
    } else {
      this.mediaplanTable.updateData()
    }
  }

  public updateOverview() {
    ReconciliationMonthly.overview(this.period).then((res: any) => {
      this.overview = res
    })
  }

  /**
   * Initialize vars
   */
  public mounted() {
    if (this.$route.query.scope && typeof this.$route.query.scope === 'string') {
      this.scope_view = this.$route.query.scope
    }
    this.table_data_list = this.$refs.mediaplanTable

    this.ready = true

    this.updateOverview()
  }
}
