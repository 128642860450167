export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'align-middle',
    show: true,
    type: 'checkbox',

  },

  {
    key: 'inventory_name',
    label: 'Inventory name',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'billing_source',
    label: 'Billing Source',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions',
    label: 'Month Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'reconciliated_impressions',
    label: '3rd Party Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions_discrepancy',
    label: 'Discrepancy',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_rate',
    label: 'Cost rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_total',
    label: 'Total Cost',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },

  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'actions',
    class: 'text-center align-middle',
  },
]
