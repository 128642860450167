import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
    type: 'checkbox',
  },
  {
    key: 'number',
    label: '#',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'agency_name',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'station_name',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'advertiser_name',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'name',
    label: 'Media Plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'start_at',
    label: 'Flight Dates',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'billing_calendar',
    label: 'Billing Callendar',
    sortable: true,
    show: true,
    // thStyle: { width: '100%' },
    class: 'text-center align-middle',
  },
  {
    key: 'billing_source',
    label: 'Billing Source',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'billing_mode',
    label: 'Billing Mode',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'past_reconciliated_impressions',
    label: 'Past Reconciliated',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'booked_impressions',
    label: 'Booked',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'month_impressions',
    label: 'Month',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'items_reconciliated_impressions',
    label: '3rd Party',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions_discrepancy',
    label: 'Discrepancy',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_rate',
    label: 'Gross Rate',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_net_rate',
    label: 'Net Rate',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_total',
    label: 'Gross Revenue Total',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_net_total',
    label: 'Net Revenue Total',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_rate',
    label: 'Cost Rate',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_total',
    label: 'Cost Total',
    sortable: false,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'net',
    label: 'Net',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'margin',
    label: 'Magin',
    sortable: false,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'actions',
    class: 'text-center align-middle',
  },
]
